import React, { useEffect, useState, useRef } from "react"
import { Link } from "@StarberryUtils";
import HTMLParser from 'html-react-parser';
import dateFormat from 'dateformat';
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import Slider from "react-slick"

import { useMatch } from "@reach/router"
// Images
import ImgPerson from "../../images/person.jpg"
import blogImg1 from "../../images/home/blog-img-01.jpg"
import blogImg2 from "../../images/home/blog-img-02.jpg"
import blogImg3 from "../../images/home/blog-img-03.jpg"
import blogImg4 from "../../images/home/blog-img-04.jpg"
import ContactCard from "../ContactCard/ContactCard"
import {Peoples, PeoplesTabs} from "../../queries/common_use_query"
import PlayVideo from "../Play/PlayVideo";
import {youtube_parser} from "../common/utils";

import "./FilterBlock.scss"
import bgCurve2 from "../../images/home/pg-logo-curve-2.png"

const FilterBlock = props => {

  const [isPlay, setPlay] = useState(false)
  const [filterCat, setFilterCat] = useState('');
  const [youtubeId, setYoutubeId] = useState('');
  const [peopleData, setPeopleData] = useState([]);

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 99999,
        settings: "unslick",
      },
    ],
  }

  const filterCateogy = (category) =>{
    //console.log("category", category);
    setFilterCat(category);
  }

  const openVideo = (youtube_id) =>{
    setPlay(true);
    setYoutubeId(youtube_id);
  }

  const {loading, error, data} = Peoples(filterCat, 1000);
  const {loading:tab_loading, error:tab_error, data:tab_data} = PeoplesTabs();

  var uniqueCat = [];
  tab_data && tab_data.peoples && tab_data.peoples.length > 0 && tab_data.peoples.map((item, index) => {
    if (uniqueCat.indexOf(item.Category) === -1) {
      if(item.Category){
        uniqueCat.push(item.Category)
      }
    }
  });

  useEffect(()=>{
    var sort_people = [];
    data && data.peoples && data.peoples.length > 0 && data.peoples.map((item, index) => { sort_people.push(item) });

    sort_people.sort((a,b) => (a.Sort_Order > b.Sort_Order) ? 1 : ((b.Sort_Order > a.Sort_Order) ? -1 : 0));

    setPeopleData(sort_people);
  },[data])

  //console.log("data", data);
  //console.log("uniqueCat", uniqueCat);

  return (
    <div className="filter-block insights video_filter">
      <span className="bg-curve"><img src={bgCurve2} alt="bg-img" /></span>
      <Container>
        <Row>
          <Col lg="12">

            {
              uniqueCat && uniqueCat.length > 0 && 
              <div className="filter-block-group">
                 <div className="animated">
                  <div className="filter-link">
                    <span className={`filter-link-btn ${!filterCat && 'filter-link-btn--active'}`} onClick={()=>setFilterCat('')}>All</span>
                    {
                      uniqueCat && uniqueCat.length > 0 && uniqueCat.map((item, index) => {
                        if(item !== "Others"){
                          return(
                            <span className={`filter-link-btn ${item === filterCat && 'filter-link-btn--active'}`} onClick={()=>filterCateogy(item)}>{item.replace(/_/g, ' ')}</span>
                          )
                        }
                        
                      })
                    }
                    {
                      uniqueCat && uniqueCat.length > 0 && uniqueCat.map((item, index) => {
                        if(item === "Others"){
                          return(
                            <span className={`filter-link-btn ${item === filterCat && 'filter-link-btn--active'}`} onClick={()=>filterCateogy(item)}>{item.replace(/_/g, ' ')}</span>
                          )
                        }
                        
                      })
                    }
                  </div>
                 </div>
              </div>
            }
            
            
             <div className="animated">
            <div className="filter-block-list text-center text-xl-left">
                {

                  peopleData && peopleData.length > 0 && peopleData.map((item, index) => {
                    
                    var youtube_url = item.Embed_Video_URL && youtube_parser(item.Embed_Video_URL);

                      if(filterCat === item.Category){
                        return(
                          <ContactCard
                            cardImg={item.Tile_Image}
                            cardTitle={item.Name}
                            date={item.Designation}
                            cardTag={''}
                            link_url={item.URL}
                            embed_video_url={item.Embed_Video_URL}
                            calendly_link={item.Calendly_Link}
                            page="our_people"
                            handleClick={()=>openVideo(youtube_url)}
                            article_id={item.id} 
                            imagetransforms={item.imagetransforms}
                          />
                        )
                      } else{
                        return(
                          <ContactCard
                            cardImg={item.Tile_Image}
                            cardTitle={item.Name}
                            date={item.Designation}
                            cardTag={''}
                            link_url={item.URL}
                            embed_video_url={item.Embed_Video_URL}
                            calendly_link={item.Calendly_Link}
                            page="our_people"
                            handleClick={()=>openVideo(youtube_url)}
                            article_id={item.id} 
                            imagetransforms={item.imagetransforms}
                          />
                        )
                      }
                      
                  })
                }             
            </div>
             </div>
          </Col>

          <PlayVideo
            isOpen={isPlay}
            isCloseFunction={setPlay}
            videoId={youtubeId}
            isAutoPlay={1}
          />
        </Row>

         
      </Container>
    </div>
  )
}

export default FilterBlock